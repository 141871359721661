<template>
  <div>
    <div class="detail-info__name">
      <h2 class="title">{{data.name}}
      <button class="delete-btn" @click="showMoreInfo = !showMoreInfo"><i class="anticon"><more-icon/></i></button>
      </h2>
      <div v-if="showMoreInfo" class="detail-info__more">
        <a href="javascript:void(0);" class="detail-info__more--delete" @click="deleteUser">{{ $t('users.delete_user') }}</a>
      </div>
    </div>
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" :tab="$t('basics.general_tab')">
        <div v-if="showMoreInfo" class="detail-info__more--overlay" @click="showMoreInfo = !showMoreInfo"></div>
        <a-form :form="form" class="detail-form">

          <div class="detail-info__column-title">{{ $t('users.user_info') }}</div>
          <a-row>
            <a-col :lg="12">
                <a-form-item :label="$t('bases.linked_organization')">
                  <a-select
                      size="large"
                      :placeholder="$t('bases.not_selected')"
                      :disabled="data.has_admin_profile"
                      @change="handleSelectChanges"
                      v-decorator="['organization_id',{rules: [
                        {required: false, message: $t('messages.requiredField')}],
                        initialValue: !isEmptyDetail && getOrganizationDetails ? getOrganizationDetails.id : undefined}]">
                    <a-select-option value="" key="">
                      {{ $t('bases.no_organization') }}
                    </a-select-option>
                    <a-select-option v-for="item in organizationsList.filter(organization => organization.status === 1 || organization.id === (getOrganizationDetails ? getOrganizationDetails.id : 0))" :value="item.id" :key="item.id">
                      {{item.name}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="Email">
                  <a-input
                      size="large"
                      :placeholder="$t('organizations.enter_email')"
                      v-decorator="['email', {rules: [
                          {required: false, message: $t('messages.requiredField')},
                          {max: 255, message: $t('messages.maxLength') + 255}],
                          initialValue: data.email}]"
                  >
                  </a-input>
                </a-form-item>
                <a-form-item :label="$t('users.first_name')">
                  <a-input
                      size="large"
                      :placeholder="$t('users.first_name_placeholder')"
                      v-decorator="['first_name', {rules: [
                          {required: false, message: $t('messages.requiredField')},
                          {max: 255, message: $t('messages.maxLength') + 255}],
                          initialValue: data.first_name}]"
                  >
                  </a-input>
                </a-form-item>
                <a-form-item :label="$t('users.last_name')">
                  <a-input
                      size="large"
                      :placeholder="$t('users.last_name_placeholder')"
                      v-decorator="['last_name', {rules: [
                          {required: false, message: $t('messages.requiredField')},
                          {max: 255, message: $t('messages.maxLength') + 255}],
                          initialValue: data.last_name}]"
                  >
                  </a-input>
                </a-form-item>
                <a-form-item class="one-row large-item" :label="`${$t('users.user_status')} (${data.is_active ? $t('basics.status.active'): $t('basics.status.inactive')})`">
                  <a-switch
                    :disabled="(!getOrganizationDetails.id) && !linkedCompany"
                    @change="onChangeSwitcher('is_active')"
                    v-decorator="['is_active', {valuePropName: 'checked', initialValue: data.is_active}]"
                  />
                </a-form-item>
            </a-col>
            <a-col :lg="12">
              <a-form-item :label="$t('organizations.step_1.user_categories')">
                  <a-select
                      size="large"
                      :placeholder="$t('organizations.step_1.user_category')"
                      v-decorator="['profile',{rules: [
                        {required: false, message: $t('messages.requiredField')}],
                        initialValue: !isEmptyDetail && data.profile ? data.profile.id : undefined}]">
                    <a-select-option value="" key="">
                      {{ $t('organizations.step_1.no_category') }}
                    </a-select-option>
                    <a-select-option v-for="(item, i) in companyCategories" :value="item.id" :key="i">
                      {{item.profile}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              <a-form-item :label="$t('users.nfc_badge')">
                  <a-input
                      size="large"
                      :placeholder="$t('users.nfc_placehodler')"
                      v-decorator="['nfc_badge_id', {rules: [
                          {required: false, message: $t('messages.requiredField')},
                          {max: 255, message: $t('messages.maxLength') + 255}],
                          initialValue: data.nfc_badge_id}]"
                  >
                  </a-input>
                </a-form-item>
                <a-form-item :label="$t('picos.user_id')">
                  <a-input
                      size="large"
                      :placeholder="$t('users.user_id_placeholder')"
                      v-decorator="['user_id', {rules: [
                          {required: true, message: $t('messages.requiredField')},
                          {pattern: /^[0-9]{0,255}$/, message: $t('messages.userIdInteger')}],
                          initialValue: data.user_id}]"
                  >
                  </a-input>
                </a-form-item>
                <a-form-item :label="$t('users.external_id')">
                  <a-input
                      size="large"
                      :placeholder="$t('users.external_id_placeholder')"
                      v-decorator="['external_id', {rules: [
                          {required: false, message: $t('messages.requiredField')},
                          {max: 255, message: $t('messages.maxLength') + 255}],
                          initialValue: data.external_id}]"
                  >
                  </a-input>
                </a-form-item>
              <a-form-item class="one-row large-item" :label="$t('users.assignAsOrganizer')">
                <a-switch
                    :disabled="!notEmptyOrganizationSelected"
                    v-decorator="['is_organizer', {valuePropName: 'checked', initialValue: data.has_admin_profile}]"
                />
              </a-form-item>

            </a-col>
          </a-row>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" :tab="$t('basics.history')">
        <a-table
            :locale={emptyText:emptyText}
            class="main-table"
            :columns="columns"
            rowKey="id"
            :data-source="data.history"
            :scroll="{y: 'calc(100% - 40px)' }"
            :pagination="false"
        >
          <template slot="date" slot-scope="text, record">
            <div v-if="record.from === null && record.to === null">--</div>
            <div v-else>{{`${fromToConvert(record.from)}`}} <br/> {{ record.to ? `${fromToConvert(record.to)}` : '-'}}</div>
          </template>
          <template slot="total_time" slot-scope="text, record">
            <div>{{totalConvert(record.total_time)}}</div>
          </template>
        </a-table>
      </a-tab-pane>
    </a-tabs>
    <div class="form-action-btn">
      <a-button
          @click="$emit('onClose')"
      >{{ $t('basics.cancel') }}</a-button>
      <a-button
          type="primary"
          @click="handleSubmit"
          :loading="loginBtn"
          :disabled="loginBtn">{{ $t('basics.save_changes') }}</a-button>
    </div>
  </div>
</template>

<script>
import { formatDateUserInfoTable } from '@/utils/helpers/dateFormat';
import moreIcon from '@/assets/icons/More.svg?inline'
import { requiredField, maxLength, deleteUserMassages, userIdInteger } from '@/utils/messages';
import { i18n } from '@/i18n';
export default {
  name: "DetailUserForm",
  components: {
    moreIcon
  },
  data() {
    return {
      formatDateUserInfoTable,
      requiredField,
      userIdInteger,
      maxLength,
      loginBtn: false,
      linkedCompany: null,
      showMoreInfo: false,
      emptyText: this.$t('bases.no_data'),
      columns: [
        {
          title: i18n.t('picos.pico_id'),
          dataIndex: 'pico_id',
          key: 'pico_id'
        },
        {
          title: i18n.t('organizations.organization_name'),
          dataIndex: 'organization',
          key: 'organization'
        },
        {
          title: i18n.t('basics.using_date'),
          dataIndex: 'date',
          key: 'date',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: i18n.t('basics.total_time'),
          dataIndex: 'total_time',
          key: 'total_time',
          scopedSlots: { customRender: 'total_time' },
        },
      ],
      history: [
        {
          "id": 11,
          "pico_id": "865187225",
          "organization": "NEXTRA",
          "from": 1620994482,
          "total_time": 23
        },
        {
          "id": 10,
          "pico_id": "865187225",
          "organization": "NEXTRA",
          "from": 1620994482,
          "to": 1620994423,
          "total_time": 23
        }
      ],
      companyCategories: null,
      notEmptyOrganizationSelected: false
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  mounted() {
    let modal = document.getElementsByClassName('modal-body')[0];
    if (modal) {
      modal.addEventListener('scroll', this.handleScroll);
    }
  },
  computed: {
    getOrganizationDetails() {
      return this.$store.getters['detailOrganization/getOrganizationDetails']
    },
    data() {
      return this.$store.getters['detailUser/getUserDetails']
    },
    organizationsList() {
      return this.$store.getters['organizations/getOrganizationsWithoutLink']
    },
    isEmptyDetail() {
      return Object.keys(this.data).length === 0
    }
  },
  watch: {
    data(data) {
      this.$store.commit('detailOrganization/SET_ORGANIZATIONS_DETAIL', {});
      this.notEmptyOrganizationSelected = !!data.organization;
      if (data.organization_id) {
        this.$store.dispatch('detailOrganization/GetOrganization', data.organization_id).then(res => {
          this.companyCategories = res.data.profiles;
          this.notEmptyOrganizationSelected = true;
        });
      }
    }
  },
  methods: {
    handleScroll () {
      let openedSelects = document.getElementsByClassName('ant-select ant-select-open');
      let selectDropdowns = document.getElementsByClassName('ant-select-dropdown');

      openedSelects.forEach(element => {
        if (element) {
          element.classList.remove(...['ant-select-open', 'ant-select-focused']);
        }
      })

      selectDropdowns.forEach(element => {
        if (element) {
          element.style.display = 'none';
        }
      })
    },
    fromToConvert(time) {
      return this.$moment.unix(time).format('D MMMM YYYY, h:mm');
    },
    totalConvert(time) {
      const days = +this.$moment.utc(time*1000).format('D') - 1 !== 0 ? +this.$moment.utc(time*1000).format('D') - 1 + "d" + " " : "";
      const hours = +this.$moment.utc(time*1000).format('HH') !== 0 ? +this.$moment.utc(time*1000).format('H') + "h" + " " : "";
      const minutes = +this.$moment.utc(time*1000).format('mm') !== 0 ? +this.$moment.utc(time*1000).format('mm') + "m" + " " : "";
      const seconds = +this.$moment.utc(time*1000).format('ss') !== 0 ? +this.$moment.utc(time*1000).format('ss') + "s" : "";
      return days + hours + minutes + seconds;
      // return +this.$moment.utc(time*1000).format('D') - 1 + "d" + " " + this.$moment.utc(time*1000).format('HH[h] mm[m] ss[s]');
    },
    handleSelectChanges(value) {
      this.notEmptyOrganizationSelected = !!value;
      if (value !== '') {
        this.linkedCompany = !!value;
        this.$store.dispatch('detailOrganization/GetOrganization', value)
          .then(res => {
            this.form.setFieldsValue({
              organization_id: res.data.id,
              profile: ""
            });
            this.companyCategories = res.data.profiles
          })
      } else {
          this.linkedCompany = true;
          this.companyCategories = [];
          this.form.setFieldsValue({
            organization_id: '',
            profile: ""
          });
      }
    },
    onChangeSwitcher(field){
      this.data.is_active = !this.data.is_active

      this.$nextTick(() => {
        this.form.validateFields([field], { force: true });
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.loginBtn = true
      this.form.validateFields((err, values) => {
        if (!err) {
          if(values.profile === "") {
            values.profile = null;
          }
          values.prev_organization_id = this.data.organization_id;
          this.$store.dispatch('detailUser/UpdateUser', {
            id: this.data.id,
            data: values
          })
            .then(() => {
              this.$message.success(this.$t('users.user_saved'))
              this.$emit('onClose');
              this.loginBtn = false
            })
            .catch(error => {
              if (error.response?.data?.errors?.organization_id == "User still has pico in use!") {
                this.$message.error(this.$t('messages.userHavePico'));
                this.loginBtn = false
                return;
              }
              if (error.response?.data?.error?.errors?.is_active) {
                if (error.response?.data?.error?.errors?.is_active[0] == "User must return all picos!") {
                  this.$message.error(this.$t('messages.userUpdateError'));
                  this.loginBtn = false
                  return;
                }
              }
              const errors = error.response.data?.error?.errors;
                if (errors) {
                    for (const [key, value] of Object.entries(errors)) {
                        this.form.setFields({
                            [key]: {
                                value: '',
                                errors: [new Error(value)]
                            }
                        });
                    }
                }
              this.loginBtn = false
            })
        } else {
          this.loginBtn = false
        }
      });
    },
    deleteUser() {
      this.$confirm({
        centered: true,
        content: this.$t('messages.deleteUserMassages'),
        okText: this.$t('basics.yes'),
        cancelText: this.$t('basics.cancel'),
        width: 490,
        closable: true,
        onOk: () => {
          this.$store.dispatch('detailUser/DeleteUser', {id:this.data.id,organizationId: this.getOrganizationDetails ? this.getOrganizationDetails.id : ''})
              .then(() => {
                this.$message.success(this.$t('users.user_deleted'))
                this.$emit('onClose')
              })
              .catch(error => {
                if (error.response.data.errors?.organization_id == "User still has pico in use!") {
                  this.$message.error(this.$t('messages.userDeleteError'));
                } else {
                  this.$message.error(error.response.data.errors);
                }
              })
        },
        onCancel: () => this.showMoreInfo = false
      })
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style scoped>
  .ant-table-row-cell-last {
    border-left: 1px solid rgba(64, 69, 78, 0.1);
  }
</style>
