var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"detail-info__name"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.data.name)+" "),_c('button',{staticClass:"delete-btn",on:{"click":function($event){_vm.showMoreInfo = !_vm.showMoreInfo}}},[_c('i',{staticClass:"anticon"},[_c('more-icon')],1)])]),(_vm.showMoreInfo)?_c('div',{staticClass:"detail-info__more"},[_c('a',{staticClass:"detail-info__more--delete",attrs:{"href":"javascript:void(0);"},on:{"click":_vm.deleteUser}},[_vm._v(_vm._s(_vm.$t('users.delete_user')))])]):_vm._e()]),_c('a-tabs',{attrs:{"default-active-key":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":_vm.$t('basics.general_tab')}},[(_vm.showMoreInfo)?_c('div',{staticClass:"detail-info__more--overlay",on:{"click":function($event){_vm.showMoreInfo = !_vm.showMoreInfo}}}):_vm._e(),_c('a-form',{staticClass:"detail-form",attrs:{"form":_vm.form}},[_c('div',{staticClass:"detail-info__column-title"},[_vm._v(_vm._s(_vm.$t('users.user_info')))]),_c('a-row',[_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('bases.linked_organization')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['organization_id',{rules: [
                      {required: false, message: _vm.$t('messages.requiredField')}],
                      initialValue: !_vm.isEmptyDetail && _vm.getOrganizationDetails ? _vm.getOrganizationDetails.id : undefined}]),expression:"['organization_id',{rules: [\n                      {required: false, message: $t('messages.requiredField')}],\n                      initialValue: !isEmptyDetail && getOrganizationDetails ? getOrganizationDetails.id : undefined}]"}],attrs:{"size":"large","placeholder":_vm.$t('bases.not_selected'),"disabled":_vm.data.has_admin_profile},on:{"change":_vm.handleSelectChanges}},[_c('a-select-option',{key:"",attrs:{"value":""}},[_vm._v(" "+_vm._s(_vm.$t('bases.no_organization'))+" ")]),_vm._l((_vm.organizationsList.filter(function (organization) { return organization.status === 1 || organization.id === (_vm.getOrganizationDetails ? _vm.getOrganizationDetails.id : 0); })),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)],1),_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {rules: [
                        {required: false, message: _vm.$t('messages.requiredField')},
                        {max: 255, message: _vm.$t('messages.maxLength') + 255}],
                        initialValue: _vm.data.email}]),expression:"['email', {rules: [\n                        {required: false, message: $t('messages.requiredField')},\n                        {max: 255, message: $t('messages.maxLength') + 255}],\n                        initialValue: data.email}]"}],attrs:{"size":"large","placeholder":_vm.$t('organizations.enter_email')}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('users.first_name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['first_name', {rules: [
                        {required: false, message: _vm.$t('messages.requiredField')},
                        {max: 255, message: _vm.$t('messages.maxLength') + 255}],
                        initialValue: _vm.data.first_name}]),expression:"['first_name', {rules: [\n                        {required: false, message: $t('messages.requiredField')},\n                        {max: 255, message: $t('messages.maxLength') + 255}],\n                        initialValue: data.first_name}]"}],attrs:{"size":"large","placeholder":_vm.$t('users.first_name_placeholder')}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('users.last_name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['last_name', {rules: [
                        {required: false, message: _vm.$t('messages.requiredField')},
                        {max: 255, message: _vm.$t('messages.maxLength') + 255}],
                        initialValue: _vm.data.last_name}]),expression:"['last_name', {rules: [\n                        {required: false, message: $t('messages.requiredField')},\n                        {max: 255, message: $t('messages.maxLength') + 255}],\n                        initialValue: data.last_name}]"}],attrs:{"size":"large","placeholder":_vm.$t('users.last_name_placeholder')}})],1),_c('a-form-item',{staticClass:"one-row large-item",attrs:{"label":((_vm.$t('users.user_status')) + " (" + (_vm.data.is_active ? _vm.$t('basics.status.active'): _vm.$t('basics.status.inactive')) + ")")}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_active', {valuePropName: 'checked', initialValue: _vm.data.is_active}]),expression:"['is_active', {valuePropName: 'checked', initialValue: data.is_active}]"}],attrs:{"disabled":(!_vm.getOrganizationDetails.id) && !_vm.linkedCompany},on:{"change":function($event){return _vm.onChangeSwitcher('is_active')}}})],1)],1),_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('organizations.step_1.user_categories')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['profile',{rules: [
                      {required: false, message: _vm.$t('messages.requiredField')}],
                      initialValue: !_vm.isEmptyDetail && _vm.data.profile ? _vm.data.profile.id : undefined}]),expression:"['profile',{rules: [\n                      {required: false, message: $t('messages.requiredField')}],\n                      initialValue: !isEmptyDetail && data.profile ? data.profile.id : undefined}]"}],attrs:{"size":"large","placeholder":_vm.$t('organizations.step_1.user_category')}},[_c('a-select-option',{key:"",attrs:{"value":""}},[_vm._v(" "+_vm._s(_vm.$t('organizations.step_1.no_category'))+" ")]),_vm._l((_vm.companyCategories),function(item,i){return _c('a-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.profile)+" ")])})],2)],1),_c('a-form-item',{attrs:{"label":_vm.$t('users.nfc_badge')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['nfc_badge_id', {rules: [
                        {required: false, message: _vm.$t('messages.requiredField')},
                        {max: 255, message: _vm.$t('messages.maxLength') + 255}],
                        initialValue: _vm.data.nfc_badge_id}]),expression:"['nfc_badge_id', {rules: [\n                        {required: false, message: $t('messages.requiredField')},\n                        {max: 255, message: $t('messages.maxLength') + 255}],\n                        initialValue: data.nfc_badge_id}]"}],attrs:{"size":"large","placeholder":_vm.$t('users.nfc_placehodler')}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('picos.user_id')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_id', {rules: [
                        {required: true, message: _vm.$t('messages.requiredField')},
                        {pattern: /^[0-9]{0,255}$/, message: _vm.$t('messages.userIdInteger')}],
                        initialValue: _vm.data.user_id}]),expression:"['user_id', {rules: [\n                        {required: true, message: $t('messages.requiredField')},\n                        {pattern: /^[0-9]{0,255}$/, message: $t('messages.userIdInteger')}],\n                        initialValue: data.user_id}]"}],attrs:{"size":"large","placeholder":_vm.$t('users.user_id_placeholder')}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('users.external_id')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['external_id', {rules: [
                        {required: false, message: _vm.$t('messages.requiredField')},
                        {max: 255, message: _vm.$t('messages.maxLength') + 255}],
                        initialValue: _vm.data.external_id}]),expression:"['external_id', {rules: [\n                        {required: false, message: $t('messages.requiredField')},\n                        {max: 255, message: $t('messages.maxLength') + 255}],\n                        initialValue: data.external_id}]"}],attrs:{"size":"large","placeholder":_vm.$t('users.external_id_placeholder')}})],1),_c('a-form-item',{staticClass:"one-row large-item",attrs:{"label":_vm.$t('users.assignAsOrganizer')}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_organizer', {valuePropName: 'checked', initialValue: _vm.data.has_admin_profile}]),expression:"['is_organizer', {valuePropName: 'checked', initialValue: data.has_admin_profile}]"}],attrs:{"disabled":!_vm.notEmptyOrganizationSelected}})],1)],1)],1)],1)],1),_c('a-tab-pane',{key:"2",attrs:{"tab":_vm.$t('basics.history')}},[_c('a-table',{staticClass:"main-table",attrs:{"locale":{emptyText:_vm.emptyText},"columns":_vm.columns,"rowKey":"id","data-source":_vm.data.history,"scroll":{y: 'calc(100% - 40px)' },"pagination":false},scopedSlots:_vm._u([{key:"date",fn:function(text, record){return [(record.from === null && record.to === null)?_c('div',[_vm._v("--")]):_c('div',[_vm._v(_vm._s(("" + (_vm.fromToConvert(record.from))))+" "),_c('br'),_vm._v(" "+_vm._s(record.to ? ("" + (_vm.fromToConvert(record.to))) : '-'))])]}},{key:"total_time",fn:function(text, record){return [_c('div',[_vm._v(_vm._s(_vm.totalConvert(record.total_time)))])]}}])})],1)],1),_c('div',{staticClass:"form-action-btn"},[_c('a-button',{on:{"click":function($event){return _vm.$emit('onClose')}}},[_vm._v(_vm._s(_vm.$t('basics.cancel')))]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loginBtn,"disabled":_vm.loginBtn},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.$t('basics.save_changes')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }